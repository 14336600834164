import React from "react";
import styled from "styled-components";

const About = () => {
  return (
    <AboutContainer>
      <Content>
        <h1>About Us</h1>
        <p>
          At Oojrah, we aim to revolutionize the e-hailing ride industry by
          putting the power back into the hands of our users. Our innovative
          platform allows customers to set their own price offers for trips,
          while drivers can respond with their own offers, creating a
          competitive and fair marketplace.
        </p>
        <GoalsSection>
          <GoalsHeading>Company Goals</GoalsHeading>
          <Goals>
            <Goal>
              <h3>Customer Empowerment</h3>
              <p>
                We believe in giving our customers the flexibility and freedom
                to choose the best ride offers that suit their needs and budget.
              </p>
            </Goal>
            <Goal>
              <h3>Driver Satisfaction</h3>
              <p>
                Our platform ensures that drivers are also fairly compensated
                and have the freedom to choose the best offers from customers.
              </p>
            </Goal>
            <Goal>
              <h3>Safety and Security</h3>
              <p>
                We prioritize the safety and security of our users by providing
                real-time tracking, secure payment methods, and reliable
                customer support.
              </p>
            </Goal>
            <Goal>
              <h3>Innovation</h3>
              <p>
                We continuously strive to innovate and improve our platform to
                provide the best possible experience for both customers and
                drivers.
              </p>
            </Goal>
          </Goals>
        </GoalsSection>
      </Content>
    </AboutContainer>
  );
};

const AboutContainer = styled.section`
  padding: 3rem 1rem;
  background-color: ${({ theme }) => theme.milkyWhite};
  color: ${({ theme }) => theme.secondary};
  text-align: center;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }
`;

const Content = styled.div`
  padding-bottom: 100px; /* Adjust this value to match or exceed the bottom bar height */
  max-width: 800px;
  margin: 0 auto;

  h1 {
    color: ${({ theme }) => theme.primary};
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
`;

const GoalsSection = styled.div`
  margin-top: 3rem;
`;

const GoalsHeading = styled.h2`
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.primary};
`;

const Goals = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
`;

const Goal = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  flex: 1 1 250px;
  margin: 1rem;
  text-align: left;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  h3 {
    color: ${({ theme }) => theme.secondary};
    margin-bottom: 1rem;
  }

  p {
    color: ${({ theme }) => theme.subtitle};
  }
`;

export default About;
