import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Modal = ({ showModal, setShowModal, message }) => {
  return (
    showModal && (
      <ModalOverlay onClick={() => setShowModal(false)}>
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
        >
          <CloseButton onClick={() => setShowModal(false)}>×</CloseButton>
          <Message>{message}</Message>
        </ModalContent>
      </ModalOverlay>
    )
  );
};

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background: ${({ theme }) => theme.white};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 500px;
  width: 100%;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.secondary};
`;

export default Modal;
