import React, { useEffect } from "react";
import styled from "styled-components";

const Proposal = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-NWKH81EGBH";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-NWKH81EGBH');
    `;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <ProposalContainer>
      <Content>
        <iframe
          src="https://forms.gle/iA9tnfDdCniJB1gL7?embedded=true"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "none",
          }}
          title="Google Form"
        ></iframe>
      </Content>
    </ProposalContainer>
  );
};

const ProposalContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export default Proposal;
