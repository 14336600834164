import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Proposal from "./pages/Proposal";
import Contact from "./pages/Contact";
import GlobalStyle from "./GlobalStyle";
import { theme } from "./theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <RouteSwitcher />
      </Router>
    </ThemeProvider>
  );
};

const RouteSwitcher = () => {
  const location = useLocation();
  const hideNavbar = location.pathname === "/proposal";

  return (
    <>
      {!hideNavbar && <Navbar />}
      {!hideNavbar && <Footer />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/proposal" element={<Proposal />} />
      </Routes>
    </>
  );
};

export default App;
