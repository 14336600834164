import React from "react";
import styled from "styled-components";
import { FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";

const Contact = () => {
  return (
    <ContactContainer>
      <Content>
        <h1>Contact Us</h1>
        <p>
          If you have any inquiries or need assistance, don't hesitate to
          contact us. We're here to help you with any questions or concerns you
          may have.
        </p>
        <ContactInfo>
          <p>
            Email: <a href="mailto:admin@oojrah.com">admin@oojrah.com</a>
          </p>
        </ContactInfo>
        <SocialMedia>
          <p>Follow us on:</p>
          <SocialLinks>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={30} />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size={30} />
            </a>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size={30} />
            </a>
          </SocialLinks>
        </SocialMedia>
      </Content>
    </ContactContainer>
  );
};

const ContactContainer = styled.section`
  padding: 3rem 1rem;
  background-color: ${({ theme }) => theme.milkyWhite};
  color: ${({ theme }) => theme.secondary};
  text-align: center;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }
`;

const Content = styled.div`
  padding-bottom: 100px; /* Adjust this value to match or exceed the bottom bar height */
  max-width: 600px;
  margin: 0 auto;

  h1 {
    color: ${({ theme }) => theme.primary};
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 2rem;
`;

const SocialMedia = styled.div`
  margin-top: 2rem;

  p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  a {
    color: ${({ theme }) => theme.secondary};
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export default Contact;
