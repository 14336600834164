import React from "react";
import styled from "styled-components";

const Description = () => {
  return (
    <DescriptionContainer>
      <h2>Oojrah App</h2>
      <p>
        Oojrah is a revolutionary e-hailing ride app that allows customers to
        set their own price offers for trips. Drivers can then respond with
        their own offers, giving customers the flexibility to choose the best
        price. With live tracking, real-time chatting, and an integrated
        e-wallet, Oojrah offers a seamless and secure transportation experience.
        Available in both Arabic and English, Oojrah empowers customers and
        drivers alike, ensuring transparency and satisfaction in every ride.
      </p>
    </DescriptionContainer>
  );
};

const DescriptionContainer = styled.section`
  padding: 2rem;
  text-align: center;
  background-color: ${({ theme }) => theme.secondary};

  h2 {
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.milkyWhite};
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 800px;
    color: ${({ theme }) => theme.line};
  }
`;

export default Description;
