import React from "react";
import styled from "styled-components";
import {
  FaMapMarkerAlt,
  FaComments,
  FaWallet,
  FaLanguage,
  FaHandshake,
  FaUserCheck,
} from "react-icons/fa";

const Features = () => {
  return (
    <FeaturesContainer>
      <Feature>
        <FaMapMarkerAlt size={40} color="#6633FF" />
        <h3>Live Tracking</h3>
        <p>Track your ride in real-time.</p>
      </Feature>
      <Feature>
        <FaComments size={40} color="#6633FF" />
        <h3>Chatting</h3>
        <p>Communicate with your driver.</p>
      </Feature>
      <Feature>
        <FaWallet size={40} color="#6633FF" />
        <h3>E-Wallet</h3>
        <p>Manage your payments easily.</p>
      </Feature>
      <Feature>
        <FaLanguage size={40} color="#6633FF" />
        <h3>Multilingual</h3>
        <p>Supports both Arabic and English.</p>
      </Feature>
      <Feature>
        <FaHandshake size={40} color="#6633FF" />
        <h3>Price Offers</h3>
        <p>Customers and drivers can choose their price offers.</p>
      </Feature>
      <Feature>
        <FaUserCheck size={40} color="#6633FF" />
        <h3>Driver Selection</h3>
        <p>Customers are free to choose their preferred driver.</p>
      </Feature>
    </FeaturesContainer>
  );
};

const FeaturesContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  background-color: ${({ theme }) => theme.background};
`;

const Feature = styled.div`
  flex: 1 1 200px;
  margin: 1rem;
  text-align: center;
  padding: 2rem;
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  h3 {
    color: ${({ theme }) => theme.secondary};
    margin-top: 1rem;
  }

  p {
    color: ${({ theme }) => theme.subtitle};
    margin-top: 0.5rem;
  }
`;

export default Features;
