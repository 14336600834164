export const theme = {
  primary: "#6633FF",
  primaryHighlight: "#6633ff33",
  secondary: "#0f172a",
  black: "#000",
  background: "#FBFBFB",
  white: "#ffffff",
  muted: "#fafafa",
  milkyWhite: "#f9f9fb",
  subtitle: "#6e6969",
  gray: "#9498a4",
  lightBorder: "#cfcfcf",
  line: "#f8f4f4",
  yellow: "#e3c902",
  yellowHighlight: "#e3c90233",
  dark: "#0c0c0c",
  red: "#ef4444",
  redHighlight: "#ef444433",
  success: "#2bc026",
  successHighlight: "#2bc02633",
  link: "#4000ff",
  driverArrivedHighlight: "#3392ff33",
  driverArrived: "#6633ff",
  customerCanceledHighlight: "#ffcce6",
  customerCanceled: "#660033",
};
