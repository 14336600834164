import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import Modal from "./Modal";
import logo from "../assets/logo3.png";
const Navbar = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Nav>
        <NavContainer>
          <LogoContainer>
            <Logo src={logo} alt="Oojrah Logo" />
          </LogoContainer>
          <NavLinks>
            <StyledNavLink to="/" exact>
              Home
            </StyledNavLink>
            <StyledNavLink to="/about">About Us</StyledNavLink>
            <StyledNavLink to="/contact">Contact Us</StyledNavLink>
            <StyledNavLink to="/privacy">Privacy Policy</StyledNavLink>
          </NavLinks>
          <ButtonContainer>
            <DownloadButton onClick={() => setShowModal(true)}>
              Download App
            </DownloadButton>
          </ButtonContainer>
        </NavContainer>
      </Nav>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        message="The app will be published soon. Stay tuned!"
      />
    </>
  );
};

const Nav = styled.nav`
  width: 100%;
  background-color: ${({ theme }) => theme.secondary};
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
`;

const NavContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  font-size: 1.1rem;
  padding: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;

  &.active {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryHighlight};
    color: ${({ theme }) => theme.primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadButton = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHighlight};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export default Navbar;
