import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import logo from "../assets/logo4.png";
import Modal from "./Modal";

const Hero = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <HeroContainer>
      <Overlay />
      <Content>
        <Logo
          src={logo}
          alt="Oojrah Logo"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        />
        <Title
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Coming Soon
        </Title>
        <Subtitle
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          A revolutionary e-hailing ride experience
        </Subtitle>
        <Button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.5 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setShowModal(true)}
        >
          Learn More
        </Button>
      </Content>
      <BackgroundAnimation />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        message="The app will be published soon. Stay tuned!"
      />
    </HeroContainer>
  );
};

const HeroContainer = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #6633ff 0%, #0f172a 100%);
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Content = styled.div`
  padding-bottom: 100px; /* Adjust this value to match or exceed the bottom bar height */
  position: relative;
  z-index: 2;
  text-align: center;
  color: ${({ theme }) => theme.white};
`;

const Title = styled(motion.h1)`
  font-size: 4rem;
  margin: 1rem 0;
  color: ${({ theme }) => theme.milkyWhite};
`;

const Subtitle = styled(motion.h2)`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.milkyWhite};
`;

const Button = styled(motion.button)`
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHighlight};
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const Logo = styled(motion.img)`
  width: 200px;
  margin-bottom: 2rem;
  animation: ${bounce} 6s infinite;
`;

const BackgroundAnimation = styled.div`
  position: absolute;
  top: -50px;
  left: -50px;
  width: 300%;
  height: 300%;
  background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.1) 10%,
      transparent 10%
    ),
    radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 10%);
  background-size: 100px 100px;
  background-position: 0 0, 50px 50px;
  animation: move 20s linear infinite;

  @keyframes move {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-50px, -50px, 0);
    }
  }
`;

export default Hero;
